var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalAgregarCuenta",attrs:{"titulo":"Crear cuenta de pasarela de pago","tamano":"modal-lg","no-aceptar":"","adicional":"Crear"},on:{"adicional":_vm.editar}},[_c('ValidationObserver',{ref:"form",staticClass:"w-100"},[_c('div',{staticClass:"row mx-0 justify-center"},[_c('div',{staticClass:"col-6"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"f-12 text-general pl-3 f-500 mt-3"},[_vm._v("Nombre de la cuenta")]),_c('el-input',{staticClass:"w-100",attrs:{"size":"small","placeholder":"Nombre"},model:{value:(_vm.model.nombre),callback:function ($$v) {_vm.$set(_vm.model, "nombre", $$v)},expression:"model.nombre"}}),_c('span',{staticClass:"text-danger w-100 f-11 pl-3"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('ValidationProvider',{attrs:{"rules":"required","name":"api_key"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"f-12 text-general pl-3 f-500 mt-3"},[_vm._v("Api Key")]),_c('el-input',{staticClass:"w-100",attrs:{"size":"small","placeholder":"api_key"},model:{value:(_vm.model.api_key),callback:function ($$v) {_vm.$set(_vm.model, "api_key", $$v)},expression:"model.api_key"}}),_c('span',{staticClass:"text-danger w-100 f-11 pl-3"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('ValidationProvider',{attrs:{"rules":"required","name":"api_login"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"f-12 text-general pl-3 f-500 mt-3"},[_vm._v("Api Login")]),_c('el-input',{staticClass:"w-100",attrs:{"size":"small","placeholder":"api_login"},model:{value:(_vm.model.api_login),callback:function ($$v) {_vm.$set(_vm.model, "api_login", $$v)},expression:"model.api_login"}}),_c('span',{staticClass:"text-danger w-100 f-11 pl-3"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('ValidationProvider',{attrs:{"rules":"required","name":"descripcion"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"f-12 text-general pl-3 f-500 mt-4"},[_vm._v("Descripción")]),_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Descripción de la Cuenta","type":"textarea","rows":5},model:{value:(_vm.model.descripcion),callback:function ($$v) {_vm.$set(_vm.model, "descripcion", $$v)},expression:"model.descripcion"}}),_c('span',{staticClass:"text-danger w-100 f-11 pl-3"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-6"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"merchant_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"f-12 text-general pl-3 f-500 mt-3"},[_vm._v("Merchandt ID")]),_c('el-input',{staticClass:"w-100",attrs:{"size":"small","placeholder":"merchant_id"},model:{value:(_vm.model.merchant_id),callback:function ($$v) {_vm.$set(_vm.model, "merchant_id", $$v)},expression:"model.merchant_id"}}),_c('span',{staticClass:"text-danger w-100 f-11 pl-3"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('ValidationProvider',{attrs:{"rules":"required","name":"account_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"f-12 text-general pl-3 f-500 mt-3"},[_vm._v("Account ID")]),_c('el-input',{staticClass:"w-100",attrs:{"size":"small","placeholder":"account_id"},model:{value:(_vm.model.account_id),callback:function ($$v) {_vm.$set(_vm.model, "account_id", $$v)},expression:"model.account_id"}}),_c('span',{staticClass:"text-danger w-100 f-11 pl-3"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('ValidationProvider',{attrs:{"rules":"required","name":"moneda"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"f-12 text-general f-500 pl-3 mt-3"},[_vm._v("Moneda")]),_c('el-select',{staticClass:"w-100",attrs:{"size":"small","placeholder":"seleccionar"},model:{value:(_vm.model.idm_moneda),callback:function ($$v) {_vm.$set(_vm.model, "idm_moneda", $$v)},expression:"model.idm_moneda"}},_vm._l((_vm.selectMonedas),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('span',{staticClass:"text-danger w-100 f-11 pl-3"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('p',{staticClass:"f-12 text-general f-500 pl-3 mt-4"},[_vm._v("Seleccionar Cedis")]),_c('el-select',{staticClass:"w-100",attrs:{"size":"small","placeholder":"seleccionar"},on:{"change":_vm.addCedis},model:{value:(_vm.id_cedis),callback:function ($$v) {_vm.id_cedis=$$v},expression:"id_cedis"}},_vm._l((_vm.selectCedis),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id,"disabled":item.disabled}})}),1),_vm._l((_vm.cedisAgregados),function(c,key){return _c('div',{key:key,staticClass:"row mx-0 bg-light-f5 border align-items-center pl-2 mt-2 br-5 f-14",staticStyle:{"height":"32px"}},[_vm._v(" "+_vm._s(c.nombre)+" ")])})],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }